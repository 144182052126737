

































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  FundDtoPagedResultDto,
  FundProjectProgressDto,
  FundUserDto,
  NotifyTemplateCreateOrUpdateDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundProjectProgressList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectProgressList extends Vue {
  statusList: any = [];
  projectId!: number;
  queryForm = {
    userName: "",
    surname: "",
    fundName: "",
  };

  detailId = 0;

  created() {
    this.fetchEnumType();
    if (this.$route.params.projectId) {
      this.projectId = Number(this.$route.params.projectId);
    }
  }

  // 获取表数据
  fetchData(params: any) {
    params.projectId = this.projectId;
    return api.fundProjectProgress.getAll(params);
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "ValidStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "fundProjectProgressCreate",
      params: {
        projectId: this.projectId.toString(),
      },
    });
  }

  // 查看详情
  jumpDetail(id: number) {
    this.detailId = id;
    this.$router.push({
      name: "fundProjectProgressDetail",
      params: {
        id: id!.toString(),
        projectId: this.projectId.toString(),
      },
    });
  }

  // 发布
  async handlePublish(id: number) {
    this.$confirm("确定要发布吗?", "提示").then(async () => {
      await api.fundProjectProgress
        .publish({ body: { id: id } })
        .then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    });
  }

  // 取消发布
  async handleCancelPublish(id: number) {
    this.$confirm("确定要取消发布吗?", "提示").then(async () => {
      await api.fundProjectProgress
        .cancelPublish({ body: { id: id } })
        .then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    });
  }

  // 编辑
  handleEdit(id: number) {
    this.$router.push({
      name: "fundProjectProgressEdit",
      params: {
        id: id!.toString(),
        projectId: this.projectId.toString(),
      },
    });
  }

  // 删除
  async handleDelete(id: number) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fundProjectProgress.delete({ id: id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
